import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/atoms/colors';
import { font } from '../../styles/atoms/typography';

const AuthorItem = ({ icon, text }) => {
  if (!text || !icon) return null;
  return (
    <Container className="author-item">
      {icon}
      <p>{text}</p>
    </Container>
  );
};

export default AuthorItem;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  p {
    ${font('text', 'md', '700')}
    color: ${colors.gray[900]};
  }
`;
